import React, { useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { Pagination, Col, Row, Layout, Table, Input, Button, DatePicker, Tooltip, Checkbox, Modal} from 'antd';
import Sidebar from '../components/Sidebar'
import { MenuOutlined, PrinterOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie'
import classNames from 'classnames';
import moment from 'moment'
import getOrder from './getOrder'
import Printer from './Printer'
import ReactToPrint from 'react-to-print';



const Orders = () => {
  const [initialData, setInitialData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState([]);
  const itemsPerPage = 10;
  const { Header, Content, Sider } = Layout;
  const [isSiderCollapsed, setIsSiderCollapsed] = useState(false);
  const token = Cookies.get('access_token');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [consigneePhone, setConsigneePhone] = useState(null);
  const [statusName, setStatusName] = useState()
  const [codeValue, Setcode] = useState()
  const [shopCodeValue, SetshopCodeValue] = useState()
  const [selectedButton, setSelectedButton] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [printVisible, setPrintVisible] = useState(false);
  const printComponentRef = useRef();  
  const [statusCounts, setStatusCounts] = useState({
    '': 0, // Tất cả đơn hàng
    '24': 0, // Chờ lấy hàng
    '20': 0, // Đơn hủy
    '8': 0, // Đang vận chuyển
    '18': 0, // Đang giao hàng
    '5': 0, // Giao thất bại
    '17': 0, // Giao thành công
    '13': 0, // Đang trả hàng
    '12': 0, // Đã trả hàng
  });


  const formattedStartDate = startDate ? startDate.format('YYYY-MM-DD') : null;
  const formattedEndDate = endDate ? endDate.format('YYYY-MM-DD') : null;
  const defaultStartDate = moment().subtract(6, 'days').format('YYYY-MM-DD');
  const defaultEndDate = moment().format('YYYY-MM-DD');
  useEffect(() => {
    const fetchDataOnLoad = async () => {
      const queryParams = {
        token,
        params: {
          page: currentPage,
          pageSize: itemsPerPage,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          code: codeValue,
          consignee_phone: consigneePhone,
          shop_code: shopCodeValue,
          status: statusName,
        },
      };

      const data = await getOrder(queryParams);
      if (data) {
        setOrders(data.data);
        setTotalItems(data.total);
        console.log(defaultStartDate)
        console.log(defaultEndDate)
        if (currentPage === 1) {
          setInitialData(data.data);
        }
      }
    };

    fetchDataOnLoad();
  }, [ currentPage, itemsPerPage, formattedEndDate, formattedStartDate, codeValue , consigneePhone , shopCodeValue, statusName,  token]);
  const fetchData = async () => {
    const queryParams = {
      token,
      params: {
        page: currentPage,
        pageSize: itemsPerPage,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        code: codeValue,
        consignee_phone: consigneePhone,
        shop_code: shopCodeValue,
        status: statusName,
      },
    };

    const data = await  getOrder(queryParams);
    if (data) {
      setOrders(data.data);
      setTotalItems(data.total);
      if (currentPage === 1) {
        setInitialData(data.data);
      }
    }
  };


  const statusStyles = {
    'Nhập kho': 'pending',
    'Đang giao hàng': 'pending',
    'Đang vận chuyển': 'pending',
    'Giao thành công': 'success',
    'Giao thất bại': 'failed',
    'Hủy đơn': 'primary',
    'Tạo đơn': 'pickup',
    'Đang trả hàng': 'failed',
    'Đã trả hàng': 'success',
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOrders = orders.slice(indexOfFirstItem, indexOfLastItem);
  
      const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(orders.length / itemsPerPage); i++) {
      pageNumbers.push(i);
      }

      const handlePageChange = (pageNumbers) => {
        setCurrentPage(pageNumbers);

        fetchData(
          pageNumbers,
          itemsPerPage,
          formattedStartDate,
          formattedEndDate,
          codeValue,
          consigneePhone,
          shopCodeValue,
          statusName
        );
      };
      const handleStatusChange = (statusName) => {
        setStatusName(statusName);
        setSelectedButton(statusName);
        setCurrentPage(1);
        fetchData();
      };
      
    
  const handleSearch = () => {
    setCurrentPage(1);
    console.log(defaultStartDate)
        console.log(defaultEndDate)
    fetchData();
  };


  const handleSiderToggle = () => {
    setIsSiderCollapsed(!isSiderCollapsed);
  };
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US').format(value);
  };
  const handleDateChange = (dates) => {
    if (dates) {
      const [startDate, endDate] = dates;
      setStartDate(startDate);
      setEndDate(endDate);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

    const columns = [
      {
        title: 'Chọn',
        dataIndex: 'selected',
        key: 'selected',
        width: '80px',
        render: (_, record) => (
          <Checkbox
            onChange={(e) => handleCheckboxChange(e, record)}
            checked={selectedRows.includes(record.key)}
          />
        ),
      },
      {
        title: 'Mã đơn hàng',
        dataIndex: 'code',
        key: 'code',
        width: '160px',
        render: (text, record) => (
          <div> 
             <Link to={`/track?code=${record.code}`}className="custom-link"><b>{record.code}</b></Link>
            <p>
            <span className={classNames("badge", statusStyles[record.status.name])}>{record.status.name}</span>
          </p>
          </div>
        )
      },
      {
        title: 'Ngày tạo',
        dataIndex: 'created_at',
        key: 'created_at',
        witdh:'150px',
        render: (text, record) => (
          <div>
            {moment(record.created_at).format('HH:mm:ss DD/MM/YYYY')}
          </div>
        )
      },
      {
        title: 'Người nhận',
        dataIndex: 'consignee_name',
        key: 'consignee_name',
        witdh:'400px',
        render: (text, record) => (
          <div>
          <p>{record.consignee_name} - {record.consignee_phone}</p>
          <p>{record.consignee_address},{record.consignee_ward_name},{record.consignee_district_name},{record.consignee_province_name}</p>
          </div>
        )
      },
      {
        title: 'Tiền thu hộ/Cước phí',
        dataIndex: 'cod_amount',
        key: 'cod_amount',
        witdh:'300px',
        render: (text, record) => (
          <div>
          <p><h3>{formatCurrency(record.cod_amount)}</h3></p>
           <h4>{formatCurrency(record.shipping_fee)}</h4>
          </div>
        )
      },
    ];



    const handleCheckboxChange = (e, record) => {
      const key = record.key;
      if (e.target.checked) {
        setSelectedRows([...selectedRows, key]);
      } else {
        setSelectedRows(selectedRows.filter((rowKey) => rowKey !== key));
      }
    };
  const dataSource = orders.map((order) => ({
    ...order,
    key: order.id,
  }));

  const handlePrintButtonClick = () => {
    setSelectedRows([]);
    setPrintVisible(true);
  };

  const handleAfterPrint = () => {
    setPrintVisible(false);
  };


  const renderButton = (text, statusValue) => (
    <Col span={1.5}>
      <Tooltip title={`Số lượng: ${statusCounts[statusValue]}`}>
        <Button
          className={selectedButton === statusValue ? 'selected-button' : ''}
          onClick={() => handleStatusChange(statusValue)}
        >
          {text}
        </Button>
      </Tooltip>
    </Col>
  );

  return (
    <Layout style={{ minHeight: '100vh'}}>
      <Sider
        width={280}
        style={{ flex: '0 0 280px', display: isSiderCollapsed ? 'none' : 'block' }}
        collapsible
        collapsed={isSiderCollapsed}
        onCollapse={handleSiderToggle}
      >
       <Sidebar/>
      </Sider>
      <Layout style={{fontFamily: 'Arial,sans-serif'}}>
        <Header     
        style={{
        background: '#FFFFFF',
        padding: '1rem',
        textAlign: 'center',
        fontSize: '24px',
        fontWeight: 'bold',
        fontFamily: 'Arial,sans-serif'
        
      }}
      >
          <Row justify="space-between">
            <Col>
              <MenuOutlined onClick={() => handleSiderToggle(!isSiderCollapsed)} 
              style={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                fontSize: '20px',
                fontFamily: 'Arial,sans-serif'}} />
            </Col>
            <Col style={{fontSize:'22px',position: 'absolute', top: '5px',marginLeft:'50px',fontFamily: 'Arial,sans-serif' }}>Danh sách đơn hàng</Col>
            <Col></Col>
          </Row></Header>
          <Content
          style={{
            margin: '16px',
            marginLeft: isSiderCollapsed ? 0 : '0px',
            marginRight: '30px',
            transition: 'margin 0.2s',
            fontFamily: 'Arial,sans-serif'
          }}
        >
            <div style={{marginLeft:'50px'}}>
            <Row gutter={[16]}>
            {renderButton('Tất cả đơn hàng', '')}
            {renderButton('Chờ lấy hàng', '24')}
            {renderButton('Đơn hủy', '20')}
            {renderButton('Đang vận chuyển', '8')}
            {renderButton('Đang giao hàng', '18')}
            {renderButton('Giao thất bại', '5')}
            {renderButton('Giao thành công', '17')}
            {renderButton('Đang trả hàng', '13')}
            {renderButton('Đã trả hàng', '12')}
            </Row>
              </div>
              <div style={{marginLeft:'50px', marginTop:'15px'}}>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <Input
                    type="text"
                    placeholder="Tìm kiếm theo số điện thoại..."
                    value={consigneePhone}
                    onChange={(e) =>  setConsigneePhone (e.target.value)}
                  />
                </Col>
                <Col span={5}>
                  <DatePicker.RangePicker
                    defaultPickerValue={[moment(defaultStartDate, 'YYYY-MM-DD'), moment(defaultEndDate, 'YYYY-MM-DD')]}
                    value={[startDate, endDate]}
                    placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                    format="YYYY-MM-DD"
                    showTime={false}
                    onChange={handleDateChange}
                  />
                </Col>
                <Col span={6}>
                  <Button onClick={handleSearch}>Tìm kiếm</Button>
                </Col>
                <Col>
                <ReactToPrint
          trigger={() => (
            <Button
              type="primary"
              icon={<PrinterOutlined />}
              disabled={selectedRows.length === 0}
            >
              In phiếu đã chọn
            </Button>
          )}
          content={() => printComponentRef.current} 
          onAfterPrint={handleAfterPrint}
        />
        <Printer
          ref={printComponentRef}
          orders={orders}
          selectedRows={selectedRows}
        />
                </Col>
              </Row>
            </div>
            <div style={{ marginTop: '20px' }} >
            <Table columns={columns} dataSource={dataSource} pagination={false} style={{marginLeft:'50px'}} classNames="table-row"  />
            <div style={{ textAlign: 'right', marginTop: '5px'  }}>
              <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={totalItems}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  )
};
export default Orders;
