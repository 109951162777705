import React from 'react';

import Sidebar from './Sidebar'

const Dashboard = () => {
  const token = localStorage.getItem("token");


  return (
    <div>
      <Sidebar/>
    </div>
  );
};

export default Dashboard;