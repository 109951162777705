import React, {forwardRef} from 'react';
import Barcode from 'react-barcode';
import '../Printer.css';
import logo512 from '../logo/logo512.png'


const Printer = forwardRef(({orders, selectedRows}, printComponentRef) => {

    function getFormattedDate(data) {
        let date = new Date(data);
        return [
                date.getMonth() + 1, date.getDate(), date.getFullYear()
            ].join('-');
    }

    return (
        <div className="print-content" ref={printComponentRef}>
            {orders
                .filter((order) => selectedRows.includes(order.id))
                .map((order) => (
                    <div key={order.id} className="page-break">
                        <div className="header">
                            <div className="text-center">
                                <img src={logo512} alt="Logo" className="logo"/>
                                <p>{getFormattedDate(order.created_at)}</p>
                            </div>
                            <div className="text-center">
                                <h3>{order.pickup_contact_name}</h3>
                                <p>{order.pickup_contact_phone}</p>
                            </div>
                        </div>
                        <div className="centered-content">
                            <Barcode
                                value={order.code}
                                width={2.2}
                                height={65}
                                displayValue={false}
                            />
                            <h3>{order.code}</h3>
                        </div>
                        <div className="body">
                            <p>
                                Người nhận: {order.consignee_name} <br/>
                                Số điện thoại: {order.consignee_phone}<br/>
                                Địa chỉ: {order.consignee_address},{order.consignee_ward_name},{order.consignee_district_name},{order.consignee_province_name}<br/>
                                Hàng hoá:{order.product[0].name} <br/>
                                Ghi chú: {order.note}
                            </p>
                        </div>
                        <div className="cod-container">
                            COD:{order.cod_amount}
                        </div>
                    </div>
                ))}
        </div>
    );
});

export default Printer;
