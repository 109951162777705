import React from 'react';
import { Menu } from 'antd';
import logoship from '../components/logoship2.png'
import { Link, useLocation } from 'react-router-dom';
import '../index.css'



const Sidebar = () => {
  const location = useLocation();

  const menuItems = [
    { key: '1', path: '/dashboard', label: 'Dashboard' },
    { key: '2', path: '/orders', label: 'Quản lý đơn hàng' },
    { key: '3', path: '/create-order', label: 'Tạo đơn hàng' },
    { key: '4', path: '/track', label: 'Tra hành trình' },
    { key: '5', path: '/logout', label: 'Logout' },
  ];

  return (
    <div className="sidebar-wrapper">
      <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '16px'}}>
          <img src={logoship} alt="Logo" width="60" height="60" />
        </div>
        {menuItems.map(item => (
          <Menu.Item key={item.key} className={location.pathname === item.path ? 'active' : ''}>
            <Link to={item.path}>
              {item.label}
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};

export default Sidebar;