import axios from 'axios';

const getOrder = async (queryParams) => {
  try {
    const response = await axios.get(
      'https://demo.hqship.vn/api/customer/orders',
      {
        headers: {
          Authorization: `Bearer ${queryParams.token}`,
        },
        params: queryParams.params,
      }
    );

    if (response.data.success) {
      return response.data.data;
    }
  } catch (error) {
    console.error('Error fetching data:', error);

    return null;
  }
};

export default getOrder;
