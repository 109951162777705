import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/Auth/PrivateRoutes';
import Auth from './components/Auth';
import Dashboard from './components/Dashboard';
import Orders from './components/Orders';
import CreateOrder from './components/Createorder';
import Tracking from './components/Tracking';
import Logout from './components/Logout'
import Printer from './components/Printer'
const App = () => {
  
  return (
    <Router>
        <Routes>
        <Route path="logout" element={<Logout/>}/>
        <Route path="" element={<Auth />} />
        <Route path="login" element={<Auth />} />
        <Route path="" element={<PrivateRoute/>}/>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="orders"  element={<Orders />} />
          <Route path="create-order" element={<CreateOrder />} />
          <Route path="track" element={<Tracking />} />
          <Route path="Printer" element={<Printer />} />
        </Routes>
    </Router>
  );
};

export default App;