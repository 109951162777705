import React, { useState, useEffect } from 'react';
import { Layout, Form, Input, Select, Button, Row, Col, Card ,List, InputNumber } from 'antd';
import axios from 'axios';
import { MenuOutlined } from '@ant-design/icons';
import Sidebar from '../components/Sidebar';
import { deburr } from 'lodash';
import Cookies from 'js-cookie';
import numeral from 'numeral';
const { Option } = Select;
const { Sider, Header, Content } = Layout;

const CreateOrder = () => {
  const [isSiderCollapsed, setIsSiderCollapsed] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [wards, setWards] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [shippingFee, setShippingFee] = useState(0);
  const [form] = Form.useForm();
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [codAmount, setCodAmount] = useState(0);
  const token = Cookies.get('access_token');
  const [searchReceivers, setSearchReceivers] = useState([]);
  const [selectedReceiver, setSelectedReceiver] = useState(null);
  const [showReceiverList, setShowReceiverList] = useState(false);
  const [inputValue, setInputValue] = useState()
  const [nameValue, setNameValue] = useState('');
  const [addressValue, setAddressValue] = useState('');
  const [productWeight, setProductWeight] = useState('');

  const handleSiderToggle = (collapsed) => {
    setIsSiderCollapsed(collapsed);
  };

  const handleDistrictChange = (selectedDistrictId) => {
    setSelectedDistrict(selectedDistrictId);
  } ;

  const handleWardsChange = (value) => {
    setWards(districts.find((district) => district.id === value)?.wards || []);
  };
  const handleFieldChange = () => {
    sendShippingFeeRequest();
  };

  const formatCurrency = value => {
    return numeral(value).format('0,0');
  };
  const handlePhoneInputChange = async (value) => {
    setInputValue(value)

    if (value.length >= 4) {
      try {
        const response = await fetch(`https://demo.hqship.vn/api/search-receivers?phone=${value}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const responseData = await response.json();
      setSearchReceivers(responseData.data);
      setSelectedReceiver(responseData.data);
      setShowReceiverList(true);
      } catch (error) {
        console.error('Error fetching API:', error);
      }
    } else {
      setShowReceiverList(false);
    }

  };
  
  useEffect(() => {
    axios
      .get('https://demo.hqship.vn/api/get-provinces')
      .then((response) => {
        setProvinces(response.data.data);
        const allDistricts = response.data.data.reduce((accumulator, province) => {
          return accumulator.concat(province.districts);
        }, []);
        setDistricts(allDistricts);
      })
      .catch((error) => {
        console.error('Lỗi khi tải danh sách tỉnh/thành phố:', error);
      });
  }, []);

  const sendShippingFeeRequest = async () => {
    if (!selectedDistrict ) {
      console.error('Chưa đủ thông tin cần thiết để tính cước phí.');
      return;
    }

    try {
      const formData = {
        shipping_type: form.getFieldValue('shipping_type') || 1,
        consigneeDistrict: selectedDistrict,
 
        weight: form.getFieldValue('productWeight'),
        cod_amount: form.getFieldValue('cod_amount') || 0,
        paid_by: form.getFieldValue('paid_by') || 1,
      };
      const axiosConfig = {
        params: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(`https://demo.hqship.vn/api/get-shipping-fee`, axiosConfig);

      if (response.data.success) {
        setShippingFee(response.data.data.money);
        setCodAmount(response.data.data.codAmount);
      } else {
        console.error('Lỗi khi lấy giá cước phí:', response.data.message);
      }
    } catch (error) {
      console.error('Lỗi khi lấy giá cước phí:', error);
    }
  };

  const handleCreate = async () => {
    try {
      const formValues = form.getFieldsValue();
      const orderPayload = {
        shop_code: "",
        order_value: formValues.order_value,
        cod_amount: formValues.cod_amount,
        note: formValues.note,
        consigneeName: formValues.consigneeName,
        consigneePhone: formValues.consigneePhone,
        consigneeAddress: formValues.consigneeAddress,
        consigneeDistrict: formValues.consigneeDistrict,
        consigneeWard: formValues.consigneeWard,
        paid_by: formValues.paid_by,
        products: [
          {
            name: formValues.productName,
            depth: formValues.productDepth || "",
            height: formValues.productHeight || "",
            width: formValues.productWidth || "",
            quantity_item: formValues.productQuantity|| "1",
            weight: formValues.productWeight,
          },
        ],
      };
  
      const response = await axios.post(
        'https://demo.hqship.vn/api/customer/order/create',
        orderPayload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        console.log('Order created successfully:', response.data.data);
        form.resetFields(['consigneeName','consigneePhone','consigneeAddress','consigneeDistrict','consigneeWard','cod_amount']

        );
        setShippingFee(0);
        setCodAmount(0);
      } else {
        console.error('Error creating order:', response.data.message);
      }
    } catch (error) {
      console.error('Error creating order:', error);
    }
  };
  useEffect(() => {
    if (selectedDistrict) {
      axios
        .get(`https://demo.hqship.vn/api/get-wards?district_id=${selectedDistrict}`)
        .then((response) => {
          setWards(response.data.data);
        })
        .catch((error) => {
          console.error('Lỗi khi tải danh sách phường/xã:', error);
        });
    } else {
      setWards([]);
    }
  }, [selectedDistrict]);

  const isSubstringIgnoreCase = (str, substr) => {
    if (typeof str === 'string' && typeof substr === 'string' && str !== '' && substr !== '') {
      const normalizedStr = deburr(str).toLowerCase();
      const normalizedSubstr = deburr(substr).toLowerCase();
      return normalizedStr.includes(normalizedSubstr);
    }
    return false;
  };

  const filterOption = (inputValue, option) => {
    const districtName = option.children && option.children;
    return isSubstringIgnoreCase(districtName, inputValue);
  };

  const getDistrictName = (districtId) => {
    const district = districts.find((dist) => dist.id === districtId);
    return district ? district.name : '';
  };
  const getWardName = (wardId) => {
    const ward = wards.find((w) => w.id === wardId);
    return ward ? ward.name : '';
  };

  const handleItemClick = (receiver) => {
    setSelectedReceiver(receiver);
    setNameValue(receiver.full_name);
    setAddressValue(receiver.address);
    handleDistrictChange(receiver.district)
    setShowReceiverList(false);
  form.setFieldsValue({
    consigneePhone: receiver.phone,
    consigneeName: receiver.full_name,
    consigneeAddress: receiver.address,
    consigneeDistrict: receiver.district,
    consigneeWard: receiver.ward,
  });
  }
 
 
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        width={280}
        style={{
          flex: '0 0 280px',
          display: isSiderCollapsed ? 'none' : 'block',
        }}
        collapsible
        collapsed={isSiderCollapsed}
        onCollapse={handleSiderToggle}
      >
        <Sidebar />
      </Sider>
      <Layout>
        <Header
          style={{
            background: '#FFFFFF',
            padding: '1rem',
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 'bold',
            fontFamily: 'Arial, sans-serif',
          }}
        >
          <Row justify="space-between">
            <Col>
              <MenuOutlined
                onClick={() => handleSiderToggle(!isSiderCollapsed)}
                style={{
                  position: 'absolute',
                  top: '10px',
                  left: '10px',
                  fontSize: '20px',
                  fontFamily: 'Arial, sans-serif',
                }}
              />
            </Col>
            <Col
              style={{
                fontSize: '22px',
                position: 'absolute',
                top: '5px',
                marginLeft: '50px',
                fontFamily: 'Arial, sans-serif',
              }}
            >
              Tạo đơn hàng
            </Col>
            <Col></Col>
          </Row>
        </Header>
        <Content
          className="flex-content"
          style={{
            margin: '16px',
            marginLeft: isSiderCollapsed ? 50 : '50px',
            transition: 'margin 0.2s',
          }}
        >
          <div className="left-content">
            <Form
              layout="vertical"
              form={form}
              onChange={sendShippingFeeRequest}
              style={{ width: '750px' }}
            >
              <Card title="Thông tin người nhận" style={{ marginBottom: '16px' }}>
                <Row gutter={10}>
                  <Col span={12}>
                      <Form.Item
                        label="Số điện thoại"
                        name="consigneePhone"
                        rules={[{ required: true, message: 'Vui lòng nhập số điện thoại' }]}
                      >
                      <Input
                      placeholder="Nhập số điện thoại"
                      autoComplete="off"
                      type="consigneePhone"
                      id="consigneePhone"
                      value={inputValue || selectedReceiver?.phone}
                      onChange={(e) => {
                        handlePhoneInputChange(e.target.value);
                        setInputValue(e.target.value);
                      }}
                    />
                    {showReceiverList && (
                     <List
                      placeholder="Nhập số điện thoại"
                      autoComplete="off"
                      dataSource={searchReceivers}
                      renderItem={(receiver) => (
                        <List.Item
                          key={receiver.id}
                          onClick={() => handleItemClick(receiver)}
                          className="receiver-item"
                        >
                        <div className="receiver-info"> 
                         <p>
                            {`${receiver.phone} - ${receiver.full_name}`}
                          </p>
                          </div>
                          <div className="receiver-details">
                          <p>
                            {`${receiver.address} - ${getDistrictName(receiver.district)}`}
                          </p>
                          </div>
                        </List.Item>
                        )}
                        />
                    )}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Họ và tên"
                        name="consigneeName"
                        rules={[{ required: true, message: 'Vui lòng nhập họ và tên' }]}
                      >
                        <Input placeholder="Họ và tên" value={selectedReceiver?.full_name}
                        onChange={(e) => setSelectedReceiver(e.target.value)}/>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Địa chỉ"
                        name="consigneeAddress"
                        form={form}
                        rules={[{ required: true, message: 'Vui lòng nhập địa chỉ' }]}
                      >
                        <Input placeholder="Nhập địa chỉ" value={addressValue} onChange={(e) => setAddressValue(e.target.value)}/>
                      </Form.Item>
                    </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Quận/Huyện"
                      name="consigneeDistrict"
                      rules={[{ required: true, message: 'Vui lòng chọn quận/huyện' }]}
                    >
                      <Select
                        placeholder="Chọn Quận/Huyện"
                        onChange={(value) => handleDistrictChange(value)}
                      >
                        {districts.map((district) => (
                        <Option key={district.id} value={district.id}>
                          {district.name}
                        </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Phường/Xã"
                      name="consigneeWard"
                      rules={[{ required: true, message: 'Vui lòng chọn phường/xã' }]}
                    >
                      <Select onChange={handleWardsChange}>
                        {wards.map((ward) => (
                          <Option key={ward.id} value={ward.id}>
                            {ward.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>

              <Card title="Thông tin hàng hoá" style={{ marginBottom: '16px' }}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Tên hàng hoá"
                      name="productName"
                      form={form}
                      rules={[{ required: true, message: 'Vui lòng nhập tên hàng hoá' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Trọng lượng"
                      name="productWeight"
                      form={form}
                      rules={[{ required: true, message: 'Vui lòng nhập trọng lượng' }]}
                    >
                      <Input  value={productWeight} onChange={(value) => setProductWeight(value)}/>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Form>
          </div>
          <div className="right-content">
            <Form
              layout="vertical"
              form={form}
              onChange={sendShippingFeeRequest}
            >
              <Card title="Thông tin vận chuyển" style={{ marginBottom: '16px' }}>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      label="Số tiền COD"
                      name="cod_amount"
                      form={form}
                      rules={[{ required: true, message: 'Vui lòng nhập số tiền COD' }]}
                    >
                       <InputNumber
                      style={{ width: '100%' }}
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value.replace(/[$\s,]/g, '')}
                      controls={false}
                    />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Giá trị bảo hiểm" name="insurance_amount">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Hình thức thanh toán"
                      name="paid_by"
                      rules={[
                        { required: true, message: 'Vui lòng chọn hình thức thanh toán' },
                      ]}
                    >
                      <Select onChange={handleFieldChange} defaultValue="1">
                        <Option value="1">Người gửi trả</Option>
                        <Option value="2">Người nhận trả</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Ghi chú" name="note">
                      <Input.TextArea />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={
                        <div>
                          <span>Cước phí: </span>
                          {shippingFee > 0 && (
                            <span style={{ fontSize: '18px', marginRight: '8px', color: 'red' }}>
                              <b>{shippingFee.toLocaleString()} VNĐ</b>
                            </span>
                          )}
                          <span style={{ marginLeft: '100px', marginRight: '8px' }}>Tổng thu hộ:</span>
                          {codAmount > 0 && (
                            <span style={{ fontSize: '18px', color: 'red' }}>
                              <b>{codAmount.toLocaleString('vi-VN')} VNĐ</b>
                            </span>
                          )}
                        </div>
                      }
                      name="shippingFee"
                      style={{ marginBottom: 0 }}
                    ></Form.Item>
                  </Col>
                </Row>
              </Card>
              <Form.Item>
                <Button type="primary" onClick={handleCreate} style={{ marginLeft: '580px' }}>
                  Tạo đơn hàng
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default CreateOrder