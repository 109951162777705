import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = ({ component: component, ...rest }) => {
  const token = localStorage.getItem("access_token");
  return token ? <Outlet/> : <Navigate to="/"/>

}

export default PrivateRoute;
