import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Input, Button, Form, Alert } from 'antd';
import logo512 from '../logo/logo512.png'


const Auth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [form] = Form.useForm();
  const [error, setError] = useState(null);

  const handleLogin = async () => {

    try {

      const response = await axios.post('https://demo.hqship.vn/api/customer/auth/login', {
      email: email,
      password: password,
      });
      Cookies.remove('access_token');
      Cookies.set('access_token', response.data.access_token);
      navigate('/orders');

    } catch (error) {
      console.error('Error during authentication:', error);
      setError('Không thể đăng nhập. Vui lòng kiểm tra lại thông tin đăng nhập.');
    }
  };


  return (
    <div className="auth-container">
      <div className="auth-form">
      <img className="auth-logo" src={logo512} alt="Logo" />
      {error && ( 
          <Alert message={error} type="error" showIcon />
        )}
    <Form form={form} onFinish={handleLogin}>
      <Form.Item name="email" rules={[{ required: true, message: 'Please input your email' }]}>
      <Input placeholder="Email or Phone" onChange={(e) => setEmail(e.target.value)} />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: 'Please input your password' }]}>
      <Input.Password placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={handleLogin}>Login</Button>
      </Form.Item>
    </Form>
    </div>
  </div>
);
};

export default Auth;