import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Layout, Input, Button, Row, Col, Card, Form, Timeline } from 'antd';
import axios from 'axios';
import Sidebar from './Sidebar'; 
import Cookies from 'js-cookie';
import moment from 'moment'; 
import { MenuOutlined } from '@ant-design/icons';

const { Header, Content, Sider } = Layout;

const Tracking = () => {
  const [isSiderCollapsed, setIsSiderCollapsed] = useState(false);
  const [trackingCode, setTrackingCode] = useState(null);
  const [trackingResult, setTrackingResult] = useState([]);
  const token = Cookies.get('access_token');
  const { code } = useParams();
  const location = useLocation();


  const handleTracking = async () => {
    try {
      const response = await axios.get(
        `https://demo.hqship.vn/api/customer/tracking-order?code=${trackingCode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setTrackingResult([response.data.data]);
        console.log(trackingResult)
      } else {
        setTrackingResult([]);
      }
    } catch (error) {
      console.error('Error tracking order:', error);
      setTrackingResult([]);
    }
  };

  const fetchData = async (code) => {
    try {
      const response = await axios.get(
        `https://demo.hqship.vn/api/customer/tracking-order?code=${code}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setTrackingResult([response.data.data]);
      } else {
        setTrackingResult([]);
      }
    } catch (error) {
      console.error('Error tracking order:', error);
      setTrackingResult([]);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryCode = queryParams.get('code');

    if (queryCode) {
      setTrackingCode(queryCode);
      fetchData(queryCode);
    }
  }, [location.search]);

  useEffect(() => {
    if (code) {
      fetchData(code);
    }
  }, [code]);




  const handleSiderToggle = () => {
    setIsSiderCollapsed(!isSiderCollapsed);
  };
  

  const columns = [
    {
      title: 'Mã đơn hàng',
      dataIndex: 'code',
      key: 'code',
      render: (text, record) =>( 
      <div>
      {record.code}
      </div>)
    },
    {
      title: 'Tên người nhận',
      dataIndex: 'consignee_name',
      key: 'consignee_name',
      render: (text, record) => record.consignee_name,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        width={280}
        style={{ flex: '0 0 280px', display: isSiderCollapsed ? 'none' : 'block' }}
        collapsible
        collapsed={isSiderCollapsed}
        onCollapse={handleSiderToggle}
      >
        <Sidebar />
      </Sider>
      <Layout>
      <Header     
        style={{
        background: '#FFFFFF',
        padding: '1rem',
        textAlign: 'center',
        fontSize: '24px',
        fontWeight: 'bold',
        fontFamily: 'Arial,sans-serif'
        
      }}
      >
          <Row justify="space-between">
            <Col>
              <MenuOutlined onClick={() => handleSiderToggle(!isSiderCollapsed)} 
              style={{
                position: 'absolute', 
                top: '10px',
                left: '10px',
                fontSize: '20px',
                fontFamily: 'Arial,sans-serif'}} />
            </Col>
            <Col style={{fontSize:'22px',position: 'absolute', top: '5px',marginLeft:'50px',fontFamily: 'Arial,sans-serif' }}>Tra hành trình</Col>
            <Col></Col>
          </Row></Header>
        <Content style={{ padding: '16px' }}>
        <Row gutter={[24]}>
  <Col span={24}>
    <div style={{ borderRadius: '6px', border: '1px solid #ccc', backgroundColor: '#ffffff', padding: '16px', marginBottom: '16px', marginLeft: '20px', marginRight: '20px' }}>
      <Row gutter={[16]}>
        <Col span={22}>
          <Input.TextArea
            placeholder="Nhập mã theo dõi"
            value={trackingCode}
            onChange={(e) => setTrackingCode(e.target.value)}
          />
        </Col>
        <Col>
          <Button type="primary" onClick={handleTracking}>
            Theo dõi
          </Button>
        </Col>
      </Row>
    </div>
  </Col>
</Row>
      <Row gutter={[16]}>
      <Col span={24}>
        <div style={{ borderRadius: '6px', border: '1px solid #ccc', backgroundColor: '#ffffff', padding: '16px', marginLeft: '20px', marginRight: '20px', height: '750px' }}>
          {trackingResult && trackingResult.map((item) => (
            <div key={item.id}>
      <Card key={item.id}>
        <h5>Thông tin người nhận:</h5>
        <Row>
        <Col span={24}>
          <div style={{ display: 'flex', alignItems: 'center', padding: '8px 0', backgroundColor: '#cccccc', fontFamily: 'Arial, sans-serif' }}>
        <Col span={7} style={{ marginLeft:'50px',fontSize:'15px'}}>
          <p style={{ margin: 0 }}>{item.code}</p>
        </Col>
        <Col span={7}style={{fontSize:'15px'}}>
          <p style={{ margin: 0 }}>{item.consignee_name}</p>
        </Col>
        <Col span={7}style={{fontSize:'15px'}}>
          <p style={{ margin: 0 }}>{item.consignee_phone}</p>
        </Col>
        <Col span={7}style={{fontSize:'15px'}}>
          <p style={{ margin: 0 }}>{item.cod_amount}</p>
        </Col>
          </div>
        </Col>
        </Row>
          </Card>
          <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <Timeline style={{marginTop: '18px'}}>
          {item.logs.map((log, index) => (
            <div key={index}>
                <h3><p style={{ marginBottom: '5px', fontSize: '12px' }}>
                  {moment(log.created_at).format('HH:mm:ss DD/MM/YYYY')}
                </p></h3>
                <p style={{ marginTop: '0', marginBottom: '10px' }}>
                  <h4>{log.location}</h4> {log.note}
                </p>
            </div>
          ))}
        </Timeline>
              </div>
              </div>
                ))}
          </div>
        </Col>
        </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Tracking;
