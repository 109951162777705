import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';


const Logout = () => {
  useEffect(() => {
    Cookies.remove('access_token');
    window.location.href = '/';
  }, []);

  return <Navigate to="/" replace />;
};

export default Logout;
